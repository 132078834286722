import React, { Dispatch, SetStateAction } from 'react';

import Button from '@amzn/meridian/button';
import { ButtonProps } from '@amzn/meridian/button/button';
import Row from '@amzn/meridian/row';

import { UserDetails } from '../../features/UserDetailsSection/UserDetailsSection';
import { UserModalData } from '../UserActionModal/UserActionModal';
import { UserActionModal } from '../UserActionModal/UserActionModal';

export type UserButtonId = 'set_list_restriction' | 'remove_list_restriction';

export interface UserActionButtonConfig<T extends string> extends Omit<ButtonProps, 'children'> {
    id: T;
    text: string;
    onConfirm: () => any;
}

interface UserActionButtonProps {
    buttons: UserActionButtonConfig<UserButtonId>[];
    modalData: UserModalData;
    setModalData: Dispatch<SetStateAction<UserModalData>>;
    user?: UserDetails;
}

export const UserActionButtons: React.FC<UserActionButtonProps> = (props: UserActionButtonProps) => {
    const { buttons, user, modalData, setModalData } = props;

    const setModalOpen = (buttonProps: UserActionButtonConfig<UserButtonId>) => {
        setModalData({ isOpen: true, buttonProps });
    };

    return (
        <Row>
            {buttons.map((button) => (
                <Button onClick={() => setModalOpen(button)} key={button.id} {...button}>
                    {button.text}
                </Button>
            ))}
            <UserActionModal modalData={modalData} setModalData={setModalData} user={user} />
        </Row>
    );
};
