import React from 'react';

import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';

import { BulkWorkflowForm } from '../../features/BulkWorkflowForm/BulkWorkflowForm';

export const BulkWorkflowCreate: React.VFC = () => {
    return (
        <>
            <Box>
                <Column spacingInset='200' minWidth='1024.px'>
                    <Heading level={1}> Create Bulk Workflow</Heading>
                </Column>
            </Box>
            <BulkWorkflowForm />
        </>
    );
};
