import React, { Dispatch, SetStateAction } from 'react';

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { UserDetails } from '../../features/UserDetailsSection/UserDetailsSection';
import { UserActionButtonConfig, UserButtonId } from '../UserActionButtons/UserActionButtons';

export interface UserModalData {
    isOpen: boolean;
    buttonProps?: UserActionButtonConfig<UserButtonId>;
}

interface UserActionModalProps {
    modalData: UserModalData;
    setModalData: Dispatch<SetStateAction<UserModalData>>;
    user?: UserDetails;
}

export const UserActionModal: React.FC<UserActionModalProps> = (props: UserActionModalProps) => {
    const { user, modalData, setModalData } = props;

    const onModalClose = () => {
        setModalData({ isOpen: false });
    };

    return (
        <Modal width={400} onClose={onModalClose} title={modalData.buttonProps?.text} open={modalData.isOpen}>
            {modalData.buttonProps ? createModalContent(user, modalData.buttonProps) : <></>}
        </Modal>
    );
};

const createHelpMessage = (user: UserDetails, buttonProps: UserActionButtonConfig<UserButtonId>): React.ReactNode => {
    switch (buttonProps.id) {
        case 'set_list_restriction':
            return (
                <Column>
                    <Text tag='ul'>
                        <li>All Existing Lists Marked as Spam</li>
                        <li>New Lists Will be Marked as Spam</li>
                    </Text>
                    <Text>Spam lists are treated as private by default, and will not show in top lists.</Text>
                </Column>
            );
        case 'remove_list_restriction':
            return (
                <Column>
                    <Text tag='ul'>
                        <li>New Lists will not be Marked as Spam</li>
                    </Text>
                    <Text>
                        Existing lists will not be impacted. Please manually audit the users lists to them to a
                        functioning state.
                    </Text>
                </Column>
            );
    }
};

const createModalContent = (
    user: UserDetails | undefined,
    buttonProps: UserActionButtonConfig<UserButtonId>
): React.ReactNode => (
    <Column spacing='200'>
        {user && <Heading level={5}>Editing: {user?.data?.user.author.authorId}</Heading>}
        {user && createHelpMessage(user, buttonProps)}
        <ModalFooter>
            <Row alignmentHorizontal='end' widths='fit'>
                <Button type='primary' size='small' onClick={() => buttonProps.onConfirm()}>
                    Confirm
                </Button>
            </Row>
        </ModalFooter>
    </Column>
);
