/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, createContext, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LIST_DETAILS, USER_DETAILS } from '../app/components/AppNavigator/FeatureLinks';
import { OSKAR_PASS_THROUGH_PARAM } from '../app/components/OskarPassThroughToggle/OskarPassThroughToggle';
import { LIST_ID_PARAM } from '../app/pages/listDetails/ListDetails';
import { AUTHOR_ID_PARAM } from '../app/pages/userDetails/UserDetails';
import { getAppSetting } from '../config/AppSettings';
import { getListORamaPassThroughFlag, setListORamaPassThroughFlag } from '../config/axiosConfig';

export type ContextType = {
    currentStage: string;
    searchInput: string;
    isPassThroughMode: boolean;
    navigateToList: (listId: string) => void;
    navigateToUser: (userId: string) => void;
};

export const DEFAULT_CONTEXT: ContextType = {
    currentStage: 'Alpha',
    searchInput: '',
    isPassThroughMode: false,
    navigateToList: () => ({}),
    navigateToUser: () => ({})
};

export const GlobalContext = createContext<ContextType>(DEFAULT_CONTEXT);

const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const currentStage = getAppSetting('stage');

    // If Oskar pass-through flag is not present in query params, fallback to what's currently stored in session state.
    // This is so that we don't need to explicitly set passThrough query param during each page navigation.
    const isPassThroughMode =
        (searchParams.get(OSKAR_PASS_THROUGH_PARAM) || getListORamaPassThroughFlag().toString()) === 'true';

    // Set pass-through flag in case session storage is empty and there's a pass-through query param present for
    // the non-default value. This is necessary because passthrough mode is set before react initialization
    setListORamaPassThroughFlag(isPassThroughMode);

    const searchInput = searchParams.get(LIST_ID_PARAM) || searchParams.get(AUTHOR_ID_PARAM) || '';

    useEffect(() => {
        if (!searchParams.has(OSKAR_PASS_THROUGH_PARAM)) {
            searchParams.set(OSKAR_PASS_THROUGH_PARAM, isPassThroughMode.toString());
            setSearchParams(searchParams);
        }
    }, []);

    const navigateTo = (pathname: string) => navigate({ pathname, search: searchParams.toString() });

    const navigateToList = (listId: string) => {
        if (searchParams.has(AUTHOR_ID_PARAM)) {
            searchParams.delete(AUTHOR_ID_PARAM);
        }
        searchParams.set(LIST_ID_PARAM, listId);
        navigateTo(LIST_DETAILS.link);
    };

    const navigateToUser = (userId: string) => {
        if (searchParams.has(LIST_ID_PARAM)) {
            searchParams.delete(LIST_ID_PARAM);
        }
        searchParams.set(AUTHOR_ID_PARAM, userId);
        navigateTo(USER_DETAILS.link);
    };

    return (
        <GlobalContext.Provider
            value={{ currentStage, searchInput, isPassThroughMode, navigateToList, navigateToUser }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export function useGlobalContext() {
    return useContext<ContextType>(GlobalContext);
}

export default GlobalContextProvider;
