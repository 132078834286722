export const PERMISSION_REQUEST_LINK =
    'https://t.corp.amazon.com/create/templates/c7fd4945-0e05-4eed-bba2-60dd532238c7';

export type StageConfig<T> = { [key in Stage]: T };

export enum Stage {
    ALPHA = 'Alpha',
    BETA = 'Beta',
    GAMMA = 'Gamma',
    PROD = 'Prod'
}

export const ACCOUNT_MAP: StageConfig<string> = {
    Alpha: '605134474265',
    Beta: '807802464351',
    Gamma: '633756141947',
    Prod: '783391723092'
};
