import { AxiosError } from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RequestErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/RequestErrorCard';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';

import { PERMISSION_REQUEST_LINK } from '../../../config/consts';
import { Workflow } from '../../../listoramaAdmin-api/generated-src';
import ListORamaAdminApiFactory from '../../../listoramaAdmin-api/ListORamaAdminApiFactory';
import StatusTable from './components/StatusTable';

export default function BulkWorkflowStatus() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [error, setError] = useState<AxiosError | undefined>(undefined);

    const { state } = useLocation();

    useEffect(() => {
        async function fetchWorkflows() {
            let paginationToken = '';
            let workflows: Workflow[] = [];

            do {
                const response = await ListORamaAdminApiFactory().listWorkflows.listWorkflows(paginationToken);
                paginationToken = response.data.paginationToken;
                workflows = [...workflows, ...response.data.workflows];
            } while (paginationToken);

            setWorkflows(workflows);
            setError(undefined);
            setIsLoading(false);
        }
        fetchWorkflows();
    }, []);

    if (error) {
        return <RequestErrorCard error={error} permissionsRequestLink={PERMISSION_REQUEST_LINK} />;
    }

    return (
        <div>
            {state && state.successMessage && <Alert type='success'>{state.successMessage}</Alert>}
            <Button onClick={() => navigate('/bulkWorkflow/create')}>Create Bulk Workflow</Button>
            <StatusTable workflows={workflows} setWorkflows={setWorkflows} isLoading={isLoading} />
        </div>
    );
}
