import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { DetailsCard, DetailsCardProps } from '@amzn/imdb-shared-meridian-components/components/DetailsCard';
import { GenericErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/GenericErrorCard';
import { RequestErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/RequestErrorCard';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { PERMISSION_REQUEST_LINK } from '../../../config/consts';
import { UserListsDetails } from '../../../listoramaAdmin-api/generated-src';
import { AuthorDetailsTable } from '../../components/AuthorDetailsTable/AuthorDetailsTable';
import {
    ListButtonId,
    ListActionButtonConfig,
    ListActionButtons
} from '../../components/ListActionButtons/ListActionButtons';
import { ListEditData, ListModalData } from '../../components/ListActionModal/ListActionModal';
import {
    UserActionButtonConfig,
    UserActionButtons,
    UserButtonId
} from '../../components/UserActionButtons/UserActionButtons';
import { UserModalData } from '../../components/UserActionModal/UserActionModal';
import { ListType, SelectedLists, UserListsDataTable } from './components/UserListsDataTable/UserListsDataTable';
import { UserDetails } from './UserDetailsSection';

type LeftColumnCardId = 'Author' | 'Predefined Lists';
type RightColumnCardId = 'Lists';

interface UserDetailsSectionViewProps {
    searchInput: string;
    userDetails: UserDetails;
    listEditData: ListEditData;
    setListEditData: Dispatch<SetStateAction<ListEditData>>;
    selectedLists: SelectedLists;
    setSelectedLists: Dispatch<SetStateAction<SelectedLists>>;
    userModalData: UserModalData;
    setUserModalData: Dispatch<SetStateAction<UserModalData>>;
    listModalData: ListModalData;
    setListModalData: Dispatch<SetStateAction<ListModalData>>;
    setListRestrcitionStatus: (userStatus: boolean) => Promise<void>;
    editListStateBatch: (data: ListEditData) => Promise<void>;
    addListFlagBatch: (data: ListEditData) => Promise<void>;
    removeListFlagBatch: (data: ListEditData) => Promise<void>;
    canLoadMore: boolean;
    onLoadMore: () => void;
}

export const UserDetailsSectionView: React.VFC<UserDetailsSectionViewProps> = (props: UserDetailsSectionViewProps) => {
    const {
        searchInput,
        userDetails,
        listEditData,
        setListEditData,
        selectedLists,
        setSelectedLists,
        userModalData,
        setUserModalData,
        listModalData,
        setListModalData,
        setListRestrcitionStatus,
        editListStateBatch,
        addListFlagBatch,
        removeListFlagBatch,
        canLoadMore,
        onLoadMore
    } = props;

    const createListActionButtons = (): ListActionButtonConfig<ListButtonId>[] => [
        {
            id: 'edit_list_state',
            text: 'Edit List States',
            onConfirm: editListStateBatch,
            disabled: Object.keys(selectedLists).filter((listId) => selectedLists[listId]).length === 0
        },
        {
            id: 'add_list_flag',
            text: 'Add List Flags',
            onConfirm: addListFlagBatch,
            disabled: Object.keys(selectedLists).filter((listId) => selectedLists[listId]).length === 0
        },
        {
            id: 'remove_list_flag',
            text: 'Remove List Flags',
            onConfirm: removeListFlagBatch,
            disabled: Object.keys(selectedLists).filter((listId) => selectedLists[listId]).length === 0
        }
    ];

    const createUserActionButtons = (): UserActionButtonConfig<UserButtonId>[] => [
        {
            id: 'set_list_restriction',
            text: 'Restrict User',
            onConfirm: () => {
                setListRestrcitionStatus(true);
            },
            disabled: userDetails.data?.user.author.isShadowbanned
        },
        {
            id: 'remove_list_restriction',
            text: 'Unrestrict User',
            onConfirm: () => {
                setListRestrcitionStatus(false);
            },
            disabled: !userDetails.data?.user.author.isShadowbanned
        }
    ];

    const createLeftColumnCards = (
        userActionButtons: UserActionButtonConfig<UserButtonId>[],
        userListDetails: UserListsDetails
    ): DetailsCardProps<LeftColumnCardId>[] => [
        {
            name: 'Author',
            CustomCardContentElement: (
                <Column>
                    <AuthorDetailsTable author={userListDetails.author} />
                    <UserActionButtons
                        buttons={userActionButtons}
                        modalData={userModalData}
                        setModalData={setUserModalData}
                        user={userDetails}
                    />
                </Column>
            )
        },
        {
            name: 'Predefined Lists',
            CustomCardContentElement: (
                <UserListsDataTable lists={userListDetails.predefinedLists} listType={ListType.PREDEFINED} />
            )
        }
    ];

    const createRightColumnCards = (
        userListDetails: UserListsDetails,
        selectedLists: SelectedLists,
        actionButtons: ListActionButtonConfig<ListButtonId>[],
        canLoadMore: boolean,
        onLoadMore: () => void
    ): DetailsCardProps<RightColumnCardId>[] => [
        {
            name: 'Lists',
            CustomCardContentElement: (
                <Column>
                    {canLoadMore ? (
                        <Alert>
                            <Row spacing='300' width='330px' wrap='down'>
                                <Text type='h100'>There are more lists to load.</Text>
                                <Button size='small' type='tertiary' onClick={onLoadMore}>
                                    Load More
                                </Button>
                            </Row>
                        </Alert>
                    ) : null}
                    <ListActionButtons
                        buttons={actionButtons}
                        listEditData={listEditData}
                        setListEditData={setListEditData}
                        modalData={listModalData}
                        setModalData={setListModalData}
                        selectedLists={selectedLists}
                    />
                    <UserListsDataTable
                        lists={userListDetails.lists}
                        listType={ListType.LIST}
                        selectableOptions={{
                            idFieldName: 'listId',
                            selected: selectedLists,
                            setSelected: setSelectedLists
                        }}
                    />
                </Column>
            )
        }
    ];

    const isLoading = () => userDetails.fetching;

    const isError = () => userDetails.error;

    const showLoader = () => {
        return (
            <>
                <Loader />
                <Text type='b500'>{userDetails.fetchingMessage}</Text>
            </>
        );
    };

    const showError = () => {
        if (userDetails.error) {
            return <RequestErrorCard error={userDetails.error} permissionsRequestLink={PERMISSION_REQUEST_LINK} />;
        } else {
            return <GenericErrorCard message={`${searchInput} is not a valid Author ID. Please try again.`} />;
        }
    };

    const showUserDetails = () => {
        if (!userDetails.data) {
            return null;
        } else {
            const userActionButtons = createUserActionButtons();
            const listActionButtons = createListActionButtons();
            const leftColumnCards = createLeftColumnCards(userActionButtons, userDetails.data.user);
            const rightColumnCards = createRightColumnCards(
                userDetails.data.user,
                selectedLists,
                listActionButtons,
                canLoadMore,
                onLoadMore
            );
            return (
                <UserDetailsContainer>
                    <Column>
                        <Heading level={1}>{`User ${userDetails.data.user.author.authorId}`}</Heading>
                        <Row alignmentVertical='top'>
                            <Column width='50%'>
                                {leftColumnCards.map((card) => (
                                    <DetailsCard
                                        key={card.name}
                                        name={card.name}
                                        CustomCardContentElement={card.CustomCardContentElement}
                                    />
                                ))}
                            </Column>
                            <Column width='50%'>
                                {rightColumnCards.map((card) => (
                                    <DetailsCard
                                        key={card.name}
                                        name={card.name}
                                        CustomCardContentElement={card.CustomCardContentElement}
                                    />
                                ))}
                            </Column>
                        </Row>
                    </Column>
                </UserDetailsContainer>
            );
        }
    };

    return <Container>{(isLoading() && showLoader()) || (isError() && showError()) || showUserDetails()}</Container>;
};

const UserDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: scroll;
    padding: 15px;
`;

const Container = styled.div`
    text-align: center;
`;
