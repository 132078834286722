import { getAppSetting } from '../config/AppSettings';
import {
    AdminAddListFlagApi,
    AdminBatchAddListFlagApi,
    AdminBatchRemoveListFlagApi,
    AdminEditListStateApi,
    AdminEditListStateBatchApi,
    AdminRemoveListFlagApi,
    AdminSetListRestrictionApi,
    CreateWorkflowApi,
    ListWorkflowsApi,
    IdDeobfuscateApi,
    IdObfuscateApi,
    ListDetailsApi,
    PingApi,
    UserDetailsApi
} from './generated-src';

export default function () {
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;

    return {
        ping: new PingApi(undefined, apiBasePath),
        listDetails: new ListDetailsApi(undefined, apiBasePath),
        userDetails: new UserDetailsApi(undefined, apiBasePath),
        editListState: new AdminEditListStateApi(undefined, apiBasePath),
        editListStateBatch: new AdminEditListStateBatchApi(undefined, apiBasePath),
        addListFlag: new AdminAddListFlagApi(undefined, apiBasePath),
        addListFlagBatch: new AdminBatchAddListFlagApi(undefined, apiBasePath),
        removeListFlag: new AdminRemoveListFlagApi(undefined, apiBasePath),
        removeListFlagBatch: new AdminBatchRemoveListFlagApi(undefined, apiBasePath),
        listIdDeobfuscate: new IdDeobfuscateApi(undefined, apiBasePath),
        listIdObfuscate: new IdObfuscateApi(undefined, apiBasePath),
        createWorkflow: new CreateWorkflowApi(undefined, apiBasePath),
        listWorkflows: new ListWorkflowsApi(undefined, apiBasePath),
        setListRestriction: new AdminSetListRestrictionApi(undefined, apiBasePath)
    };
}
