import { ACCOUNT_MAP } from './consts';

export interface IAppSettings {
    apiUrl: string;
    stage: string;
    accountId: string;
}

let cache = {} as IAppSettings;

export function getAppSetting(key: string) {
    return cache[key];
}

export function initializeAppSettings(settings: IAppSettings) {
    cache = { ...settings };
    Object.freeze(cache);
}

/**
 * @returns The accountId for the current stage or the local settings accountId
 */
export function getAccountId() {
    return cache.accountId || ACCOUNT_MAP[cache.stage];
}
