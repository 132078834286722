import React from 'react';

import { CustomRowTableCellProps, Header } from '@amzn/imdb-shared-meridian-components/components/DataTable';

import { ListItemDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { ListDataTable } from '../../../../components/ListDataTable/ListDataTable';

export type ColumnId = 'position' | 'theConst' | 'itemText' | 'originalDescription' | 'modified';

interface ListItemsDataTableProps {
    listItems: ListItemDetails[];
    listItemsTexts: Record<string, string>;
}

export const ListItemsDataTable: React.FC<ListItemsDataTableProps> = (props: ListItemsDataTableProps) => {
    const { listItems, listItemsTexts } = props;
    listItems.map((li) => addListItemText(li, listItemsTexts));

    return (
        <ListDataTable
            headers={createHeaders()}
            rows={listItems.map((li) => addListItemText(li, listItemsTexts))}
            showPaginationOptions={true}
            customRowTableCell={CustomRowTableCell}
            emptyTableText={'List is empty.'}
        />
    );
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<string>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'originalDescription':
            return (
                <DefaultTableCellContent
                    data={row.originalDescription ? row.originalDescription.toString().substring(0, 100) : ''}
                />
            );
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const addListItemText = (li: ListItemDetails, listItemsTexts: Record<string, string>): any => {
    li.itemText = li.theConst in listItemsTexts && listItemsTexts[li.theConst] ? listItemsTexts[li.theConst] : 'N/A';
    return li;
};

const createHeaders = (): Header<ColumnId>[] => [
    {
        id: 'position',
        label: 'Position'
    },
    {
        id: 'theConst',
        label: 'Const'
    },
    {
        id: 'itemText',
        label: 'Item'
    },
    {
        id: 'originalDescription',
        label: 'Abbreviated Item Description'
    },
    {
        id: 'modified',
        label: 'Last Modified'
    }
];
