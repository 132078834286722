import orderBy from 'lodash/orderBy';
import React, { useState, Dispatch, SetStateAction } from 'react';

import {
    CustomRowTableCellProps,
    DataTable,
    Header,
    OnSortArgs
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import Alert from '@amzn/meridian/alert';
import { AlertType } from '@amzn/meridian/alert/alert';
import { TableSortDirection } from '@amzn/meridian/table/table';

import { Workflow } from '../../../../listoramaAdmin-api/generated-src';

export type ColumnId =
    | 'status'
    | 'requestId'
    | 'created'
    | 'modified'
    | 'context'
    | 'operator'
    | 'workflowType'
    | 'total'
    | 'success'
    | 'skipped'
    | 'failed';

export enum JobStatus {
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING'
}

type Props = {
    workflows: Workflow[];
    setWorkflows: Dispatch<SetStateAction<Workflow[]>>;
    isLoading: boolean;
};

export const translateToLodashSortDirection = (sortDirection: TableSortDirection) => {
    return sortDirection === 'ascending' ? 'asc' : 'desc';
};

export default function StatusTable({ workflows, setWorkflows, isLoading }: Props) {
    const [sortColumn, setSortColumn] = useState<ColumnId>('created');
    const [sortDirection, setSortDirection] = useState<TableSortDirection>('descending');

    workflows = orderBy(workflows, [sortColumn], [translateToLodashSortDirection(sortDirection)]);

    const onSort = ({ sortColumn, sortDirection }: OnSortArgs<ColumnId>) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
        const newWorkflows = orderBy(workflows, [sortColumn], [translateToLodashSortDirection(sortDirection)]);
        setWorkflows(newWorkflows);
    };
    return (
        <DataTable
            data={{
                headers: createHeaders(),
                rows: workflows
            }}
            isLoading={isLoading}
            sort={{
                column: sortColumn,
                direction: sortDirection,
                onSort: onSort
            }}
            CustomRowTableCell={CustomRowTableCell}
        />
    );
}

const getAlertTypeForStatus = (status: JobStatus): AlertType => {
    switch (status) {
        case JobStatus.SUCCEEDED:
            return 'success';
        case JobStatus.FAILED:
            return 'error';
        case JobStatus.RUNNING:
            return 'informational';
        default:
            return 'warning';
    }
};
const StatusColorTableCellContent = ({ status }: { status: JobStatus }) => (
    <Alert size={'medium'} type={getAlertTypeForStatus(status)}>
        {status}
    </Alert>
);

const DateTimeTableCellContent = ({ dateValue }: { dateValue: number }) => (
    <span> {new Date(dateValue * 1000).toLocaleString()} </span>
);

const DefaultTableCellContent = ({ content }) => <>{content}</>;

const CustomRowTableCell: React.FC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'status':
            return <StatusColorTableCellContent status={row[headerId as JobStatus]} />;
        case 'created':
        case 'modified':
            return <DateTimeTableCellContent dateValue={row[headerId] as number} />;
        case 'total':
        case 'success':
        case 'skipped':
        case 'failed':
            return <DefaultTableCellContent content={row?.['workflowStats'][headerId] ?? ''} />;
        default:
            return <DefaultTableCellContent content={row?.[headerId] ?? ''} />;
    }
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'status',
            label: 'Status'
        },
        {
            id: 'requestId',
            label: 'Step Function ID'
        },
        {
            id: 'created',
            label: 'Created'
        },
        {
            id: 'modified',
            label: 'Modified'
        },
        {
            id: 'context',
            label: 'Context'
        },
        {
            id: 'operator',
            label: 'Agent'
        },
        {
            id: 'workflowType',
            label: 'Workflow Type'
        },
        {
            id: 'total',
            label: 'Total'
        },
        {
            id: 'success',
            label: 'Successes'
        },
        {
            id: 'skipped',
            label: 'Skips'
        },
        {
            id: 'failed',
            label: 'Failures'
        }
    ];
};
