import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { CombinedError, useQuery } from 'urql';

import { useGlobalContext } from '../../../context/useGlobalContext';
import { ListDetailsResponse, ListType } from '../../../listoramaAdmin-api/generated-src/api';
import ListORamaAdminApiFactory from '../../../listoramaAdmin-api/ListORamaAdminApiFactory';
import { ListEditData, ListModalData } from '../../components/ListActionModal/ListActionModal';
import { ListDetailsSectionView } from './ListDetailsSectionView';
import {
    getListItemsTextQuery,
    ItemsTextQuery,
    ItemsTextQueryVariables,
    ItemText
} from './queries/GetListItemsTextQuery';

export interface ListDetails {
    data?: ListDetailsResponse;
    fetching: boolean;
    fetchingMessage?: string;
    error?: AxiosError;
}
export interface ListItemsTexts {
    fetching: boolean;
    error?: CombinedError;
    data: Record<string, string>;
}

export const ITEMS_PER_LOAD = 250;

export const ListDetailsSection: React.FC = () => {
    const { searchInput, isPassThroughMode } = useGlobalContext();
    const [listDetails, setListDetails] = useState<ListDetails>({ fetching: false });
    const [listEditData, setListEditData] = useState<ListEditData>({});
    const [itemTexts, setItemTexts] = useState<ListItemsTexts>({ fetching: false, data: {} });
    const [newItemConsts, setNewItemConsts] = useState<Array<string>>([]);
    const [modalData, setModalData] = useState<ListModalData>({ isOpen: false });
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

    const [newItemsTextQuery] = useQuery<ItemsTextQuery, ItemsTextQueryVariables>({
        query: getListItemsTextQuery(listDetails.data ? listDetails.data.list.listType : ListType.Lists),
        variables: { consts: newItemConsts }
    });

    useEffect(() => {
        setItemTexts((prevState) => {
            const data = prevState.data;
            if (newItemsTextQuery.data?.consts) {
                newItemsTextQuery.data?.consts.forEach((itemText) => {
                    if (!(itemText.id in data)) {
                        data[itemText.id] = getListItemText(itemText);
                    }
                });
            }
            return {
                fetching: newItemsTextQuery.fetching,
                error: newItemsTextQuery.error,
                data: data
            };
        });
    }, [newItemsTextQuery.fetching]);

    useEffect(() => {
        if (searchInput) {
            fetchListDetails(searchInput, false);
        }
    }, [searchInput, isPassThroughMode]);

    const getListItemText = (itemText: ItemText): string =>
        itemText.seriesInfo
            ? `${itemText.seriesInfo.parentTitle.text.value}: ${itemText.text.value}`
            : itemText.text?.value || itemText.text || 'N/A - Invalid Const';

    const onLoadMore = async () => {
        fetchListDetails(searchInput, true);
    };

    const fetchListDetails = async (listId: string, isLoadingMore: boolean) => {
        try {
            setModalData({ isOpen: false });
            if (!isLoadingMore) {
                setListDetails({ fetching: true, fetchingMessage: `Loading list ${searchInput}...`, error: undefined });
                const response = await ListORamaAdminApiFactory().listDetails.listDetails(listId, ITEMS_PER_LOAD);
                setNewItemConsts(response.data.list.items.map((item) => item.theConst));
                setListDetails((prevState) => ({
                    ...prevState,
                    data: response.data,
                    fetching: false
                }));
                setListEditData({ state: response.data.list.state });
                setCanLoadMore(response.data.list.items.length < response.data.list.size);
            } else if (listDetails.data !== undefined) {
                setListDetails((prevState) => ({
                    ...prevState,
                    fetching: true,
                    fetchingMessage: `Loading more items for list ${searchInput}...`,
                    error: undefined
                }));
                const response = await ListORamaAdminApiFactory().listDetails.listDetails(
                    listId,
                    ITEMS_PER_LOAD,
                    listDetails.data.list.items.length
                );
                listDetails.data.list.items.push(...response.data.list.items);
                setListDetails((prevState) => ({ ...prevState, data: listDetails.data, fetching: false }));
                setNewItemConsts(response.data.list.items.map((item) => item.theConst));
                setCanLoadMore(listDetails.data.list.items.length < response.data.list.size);
            } else {
                throw new Error(
                    'An attempt was made to load more data for an undefined request, please reach out the dev team to report this bug'
                );
            }
        } catch (e) {
            setListDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const editListState = async (data: ListEditData) => {
        try {
            if (data.state && listDetails.data) {
                setListDetails({
                    fetching: true,
                    fetchingMessage: `Setting state to ${data.state} for ${searchInput}...`
                });
                await ListORamaAdminApiFactory().editListState.adminEditListState({
                    customerId: listDetails.data.author.authorId,
                    listId: listDetails.data.list.listId,
                    state: data.state
                });
                fetchListDetails(searchInput, false);
            }
        } catch (e) {
            setListDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const addListFlag = async (data: ListEditData) => {
        try {
            if (data.flag && listDetails.data) {
                setListDetails({ fetching: true, fetchingMessage: `Adding ${data.flag} flag to ${searchInput}...` });
                await ListORamaAdminApiFactory().addListFlag.adminAddListFlag({
                    customerId: listDetails.data.author.authorId,
                    listId: listDetails.data.list.listId,
                    listFlag: data.flag
                });
                fetchListDetails(searchInput, false);
            }
        } catch (e) {
            setListDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const removeListFlag = async (data: ListEditData) => {
        try {
            if (data.flag && listDetails.data) {
                setListDetails({ fetching: true, fetchingMessage: `Removing ${data.flag} flag on ${searchInput}...` });
                await ListORamaAdminApiFactory().removeListFlag.adminRemoveListFlag({
                    customerId: listDetails.data.author.authorId,
                    listId: listDetails.data.list.listId,
                    listFlag: data.flag
                });
                fetchListDetails(searchInput, false);
            }
        } catch (e) {
            setListDetails({ error: e as AxiosError, fetching: false });
        }
    };

    return (
        <ListDetailsSectionView
            searchInput={searchInput}
            listDetails={listDetails}
            listEditData={listEditData}
            listItemTexts={itemTexts}
            setListEditData={setListEditData}
            modalData={modalData}
            setModalData={setModalData}
            editListState={editListState}
            addListFlag={addListFlag}
            removeListFlag={removeListFlag}
            canLoadMore={canLoadMore}
            onLoadMore={onLoadMore}
        />
    );
};
