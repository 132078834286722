import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import GlobalContextProvider from '../../../context/useGlobalContext';
import { BulkWorkflowCreate } from '../../pages/bulkWorkflow/BulkWorkflowCreate';
import BulkWorkflowStatus from '../../pages/bulkWorkflow/BulkWorkflowStatus';
import { Home } from '../../pages/home/Home';
import { ListDetails } from '../../pages/listDetails/ListDetails';
import { UserDetails } from '../../pages/userDetails/UserDetails';
import { Layout } from '../Layout';

export const AppNavigator: React.FC = () => {
    return (
        <BrowserRouter>
            <GlobalContextProvider>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route path='' element={<Home />} />
                        <Route path='bulkWorkflow'>
                            <Route path='' element={<BulkWorkflowStatus />} />
                            <Route path='create' element={<BulkWorkflowCreate />} />
                        </Route>
                        <Route path='listDetails' element={<ListDetails />} />
                        <Route path='userDetails' element={<UserDetails />} />
                        <Route path='*' element={<Navigate to='/' replace />} />
                    </Route>
                </Routes>
            </GlobalContextProvider>
        </BrowserRouter>
    );
};
